<template>
    <div class="">
        <slot :scope="{ pagination, fetch, loading }" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        courseSection: { required: false, type: Object, default: null },
        search: { required: false, type: String, default: null },
        query: { required: false, type: Object, default: () => ({}) },
        control: { required: false, type: Number },
        specializationsIds: { required: false, type: Array }
    },
    data: () => ({
        timeout: null,
        pagination: { data: [] },
        loading: false
    }),
    computed: mapState({
        user: ({ session }) => session.user
    }),
    methods: {
        ...mapActions({
            fetchStudents: 'courseSections/students',
            fetchStudentsAsAdmin: 'students/index',
        }),
        fetch(_query = {}, sectionHasChange = false) {
            this.loading = !this.loading
            if (this.user.isTeacher || this.control === 1) {
                const query = this.$repositoryUtils.withoutInvalidValues({
                    ...this.courseSection,
                    filters: {...this.query, ..._query}, context: 'pagination'
                })
                if(sectionHasChange){
                    this.loading = !this.loading
                    this.$emit('done', false)
                } else {
                    this.fetchStudents(query)
                        .then(response => {
                            this.pagination = response.data
                            this.$router.replace({ query })
                                .catch(() => null)
                        })
                        .finally(() => {
                            this.loading = !this.loading
                            this.$emit('done', false)
                        })
                }
            } else if (!this.user.isTeacher || this.control === 0) {
                const query = this.$repositoryUtils.withoutInvalidValues({
                    ...this.query,
                    ..._query
                })
                this.fetchStudentsAsAdmin(query)
                    .then(pagination => {
                        this.pagination = pagination
                        this.$router.replace({ query })
                            .catch(() => null)
                    })
                    .finally(() => {
                        this.loading = !this.loading
                        this.$emit('done', false)
                    })
            }
        }
    },
    watch: {
        query: {
            deep: true,
            handler(val) {
                if (this.user.isCoordinator && this.control === 0) {
                    if(val.period || val.specialization ){
                        if(val.specialization.length){
                            if(val.specialization === 'todos'){
                                this.fetch({...val, specialization: this.specializationsIds, search: this.search})
                            } else{
                                const lastIndex = val.specialization.length - 1
                                this.fetch({...val, specialization: val.specialization[lastIndex].code, search: this.search})
                            }
                        } else{
                            this.fetch({...val, specialization: val.specialization, search: this.search})
                        }
                    }
                }
            }
        
        },
        control(val){
            this.pagination = {data: []}
            val === 0 ? this.fetch({specialization: this.query.specialization === 'todos' ? this.specializationsIds : this.query.specialization, search: this.search},true) : null             
        },
        search(search) {
            if (this.user.isCoordinator || this.user.isAdmind ) {
                if(this.control === 0){
                    if (this.timeout) {
                        clearTimeout(this.timeout)
                    }
                    if (search !== null) {
                        this.timeout = setTimeout(() => {
                            this.fetch({...this.query, search: search, specialization: this.query.specialization === 'todos' ? this.specializationsIds : this.query.specialization})
                        }, 1000)
                    }   
                } else{
                    if (this.timeout) {
                        clearTimeout(this.timeout)
                    }
                    if (search !== null && this.courseSection.course_id != null) {
                        this.timeout = setTimeout(() => {
                            this.fetch({...this.query, search: search, specialization: null})
                        }, 1000)
                    }
                }
            }
            if (this.user.isTeacher) {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                if (search !== null && this.courseSection.course_id != null) {
                    this.timeout = setTimeout(() => {
                        this.fetch({...this.query, search: search})
                    }, 1000)
                }
            }
        },
        'loading'(val){
            this.$emit('update-loading', val)
        },
        'pagination'(val){
            this.$emit('students-data', val.data)
        },
        courseSection(val) {
            if (val && this.user.isTeacher || this.control === 1) {
                this.fetch({...this.query, search: this.search, specialization: null, section: val.section_id, cocurse: val.course_id})
            }
        }
    },
    // mounted(){
    //     this.fetch();
    // }
}
</script>
